#header {
  color: #01402b;
  height: 40px;
  background-color: #fff;
  padding: 0 14px 0 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999999;
  min-width: 400px !important;
}

.logo {
  padding: 10px;
  margin: 10px;
}

.toolbar {
  padding: 0 !important;
}

.headerLight {
  color: #706969 !important;
  background-color: #eeeeee !important;
}

#navSessionInfo {
  position: absolute;
}

#navButtons {
  position: absolute;
  left: 0;
  right: 0;
  min-width: 400px;
}

#navChatButton {
  position: absolute;
  right: 10px;
  top: 0;
}

#titleContent {
  max-width: 100px;
  background-color: #494949;
  margin: 5px -18px;
  padding: 0px 15px;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  float: right;
  margin-top: 20px;
}

.titleContentLight {
  background-color: #dfdfdf !important;
  color: #000;
}

#header_img {
  max-width: 135px;
  margin-right: 10px;
  margin-top: 10px;
}

#session-title {
  font-family: 'Open Sans', sans-serif;
}


#point {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 12px;
  right: 33px;
  border-radius: 50%;
  background-color: #ffa600;
  border: 1px solid #000;
  z-index: 99999;
}

.pointLight {
  border: 1px solid #ffffff !important;
}


@media only screen and (max-width: 700px) {

  #titleContent,
  #navChatButton {
    display: none;
  }
}